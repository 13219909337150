import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Monitor bus`}</h1>
    <p>{`This is not my invention but something I picked up while Googling, props to poonna on KVR forums. In their words:`}</p>
    <p>{`I create an Effect track and name it "Control Room", add an Audio Receiver there, setting the source to "Master (POST)", and set the audio output to "Studio" (that's the main output). Then I add Sonarworks on this track after the Audio Receiver. What you hear will come out of this track, but Sonarworks won't affect the render.`}</p>
    <p>{`For "Master" track, I set its audio output to something else. It could be "Headphones" or "No output" -- doesn't really matter much. Other tracks are still routed to Master as usual. All the master bus effects are also put here as usual. Also, render is done on Master. It works well for me, because I don't have to route other tracks to somewhere else.`}</p>
    <p>{`submitted by `}<a parentName="p" {...{
        "href": "https://discordapp.com/channels/121395249003233280/649293987848519691/715090034465701970"
      }}>{`ilmai`}</a></p>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      